import { Banners } from "../banners";

export const Movies = () => {
  const body = document.body;
  const urlParams = new URLSearchParams(window.location.search);
  const paramsValue = urlParams.get("params");
  const movies = document.querySelector(".m-movies");

  if (movies) {
    const movieList = movies.querySelector(".m-movies__list");

    if (movieList && paramsValue && isValidString(paramsValue)) {
      const movieItems = movieList.querySelectorAll(".c-poster");

      body.classList.add("is--3rd-in");
      body.classList.add("is--1st-out");

      movieItems.forEach((item, index) => {
        item.className = "c-poster";
        item.classList.add(`c-poster--${index + 1998}-${paramsValue[index]}`);
      });
    } else {
      body.classList.add("is--1st-in");
      Banners();
    }
  }
};

function isValidString(params: string) {
  if (params.length !== 25) {
    return false;
  }

  const pattern = /^[1-9]{25}$/;
  return pattern.test(params);
}
