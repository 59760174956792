import { CLASS_IS_ACTIVE } from "../../_utilities/variables";

export const Overlayer = () => {
  const buttonPolicy = document.querySelector("[data-button-policy]");
  const buttonClose = document.querySelector("[data-button-close]");
  const policy = document.getElementById("policy");

  buttonPolicy?.addEventListener("click", () => {
    policy?.classList.add(CLASS_IS_ACTIVE);
  });
  buttonClose?.addEventListener("click", () => {
    policy?.classList.remove(CLASS_IS_ACTIVE);
  });
};
