import { Header } from "../../modules/header";

export const Button = () => {
  const buttonStart = document.querySelector("[data-button-start]");
  const buttonShare = document.querySelector("[data-button-share]");

  if (buttonStart) {
    buttonStart.addEventListener("click", () => {
      document.body.classList.add("is--1st-out");
      document.body.classList.add("is--2nd-in");
      document.body.classList.remove("is--1st-in");
      Header();

      const swiperTop = document.querySelector<HTMLElement>(
        ".m-banners__line--top .swiper"
      )?.swiper;

      const swiperBottom = document.querySelector<HTMLElement>(
        ".m-banners__line--bottom .swiper"
      )?.swiper;

      setTimeout(() => {
        swiperTop.destroy();
        swiperBottom.destroy();
      }, 1000);
    });
  }

  if (buttonShare) {
    buttonShare.addEventListener("click", () => {
      const href = window.location.href;
      window.open(
        "http://www.facebook.com/sharer.php?u=" + href,
        "",
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,width=600,height=400,resizable=1"
      );
    });
  }
};
