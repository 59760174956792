export const Header = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramsValue = urlParams.get("params");
  const header = document.querySelector(".m-header");
  const content = document.querySelector(
    ".m-header__content"
  ) as HTMLDivElement;

  // const textElement = header?.querySelector("h2");

  // if (textElement) {
  //   const text = textElement.innerText;
  //   if (text) {
  //     const wrappedText = text
  //       .split("")
  //       .map((letter) => `<span>${letter}</span>`)
  //       .join("");

  //     textElement.innerHTML = wrappedText;
  //   }
  // }

  if (!paramsValue && !document.body.classList.contains("is--1st-out")) {
    document.body.classList.add("is--1st-in");
  } else {
    if (content && header) {
      document.body.classList.remove("is--1st-in");

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }
};
