// utilities
import { debounce } from "./_utilities/debounce.js";
import { throttle } from "./_utilities/throttle.js";
import { triggerEvent } from "./_utilities/trigger-event.js";
import { is_mobile } from "./_utilities/navigator.js";

//components
import { Button } from "./components/button/index";

//modules
import { SwiperModule } from "./modules/swiper/index";
import { Header } from "./modules/header/index";
import { Movies } from "./modules/movies/index.js";
import { Overlayer } from "./modules/overlayer/index.js";

/**
 * Binds all events to DOM objects.
 * @function _bindEvents
 */
function _bindEvents() {
  window.addEventListener(
    "resize",
    debounce(() => triggerEvent(window, "resize:smart"), 250)
  );
  window.addEventListener(
    "scroll",
    throttle(() => triggerEvent(window, "scroll:smart"), 250),
    { passive: true }
  );
}

_bindEvents();

Button();
Header();
Movies();
Overlayer();
SwiperModule();
