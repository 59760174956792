import Swiper from "swiper";
import { Controller, Navigation, Parallax, Thumbs } from "swiper/modules";
import {
  CLASS_IS_ACTIVE,
  CLASS_IS_DISABLED,
  CLASS_IS_HIDE,
} from "../../_utilities/variables.js";
import { Movies } from "../movies/index.js";
import { postData } from "../../_utilities/fetch.js";

Swiper.use([Controller, Navigation, Parallax, Thumbs]);

export const SwiperModule = () => {
  const buttonFinish = document.querySelector<HTMLButtonElement>(
    "[data-button-finish]"
  );
  const buttonsReset = document.querySelectorAll<HTMLButtonElement>(
    "[data-button-reset]"
  );
  const slideRadios = document.querySelectorAll<HTMLInputElement>(
    "input[type='radio']"
  );
  let movies: { name: string; value: string }[] = [];

  const thumbs = new Swiper(".m-swiper .swiper-thumbs", {
    slidesPerView: "auto",
    centeredSlides: true,
    slideToClickedSlide: true,
    allowSlideNext: false,
    allowSlidePrev: false,
  });
  const swiper = new Swiper(".m-swiper .swiper", {
    allowSlideNext: false,
    parallax: true,
    speed: 1000,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    on: {
      slidePrevTransitionStart: function (swiper) {
        if (swiper.navigation.nextEl[0].classList.contains(CLASS_IS_HIDE)) {
          swiper.navigation.nextEl[0].classList.remove(CLASS_IS_HIDE);
        }
        if (buttonFinish && !buttonFinish.classList.contains(CLASS_IS_HIDE)) {
          buttonFinish.classList.add(CLASS_IS_HIDE);
        }
      },
      reachEnd: function (swiper) {
        swiper.navigation.nextEl[0].classList.add(CLASS_IS_HIDE);
        buttonFinish?.classList.remove(CLASS_IS_HIDE);
        buttonFinish?.classList.add(CLASS_IS_DISABLED);

        if (buttonFinish && movies.length === swiper.slides.length) {
          buttonFinish.classList.remove(CLASS_IS_DISABLED);
          buttonFinish.disabled = false;
        }
      },
      slideNextTransitionStart: function (swiper) {
        const radios = swiper.slides[
          swiper.activeIndex
        ].querySelectorAll<HTMLInputElement>("input[type='radio']");

        swiper.allowSlideNext = Array.from(radios).some(
          (radio) => radio.checked
        );

        if (!swiper.allowSlideNext) {
          swiper.navigation.nextEl[0].classList.add(CLASS_IS_DISABLED);
        }
      },
      slideNextTransitionEnd: function (swiper) {
        if (swiper.activeIndex + 1 < swiper.slides.length) {
          const posters =
            swiper.slides[
              swiper.activeIndex + 1
            ].querySelectorAll<HTMLDivElement>(".lazy");

          posters.forEach((poster) => {
            poster.classList.remove("lazy");
          });
        }
        const radios = swiper.slides[
          swiper.activeIndex
        ].querySelectorAll<HTMLInputElement>("input[type='radio']");

        swiper.allowSlideNext = Array.from(radios).some(
          (radio) => radio.checked
        );

        if (swiper.allowSlideNext) {
          swiper.navigation.nextEl[0].classList.remove(CLASS_IS_DISABLED);
        }
        // else {
        //   swiper.navigation.nextEl[0].classList.add(CLASS_IS_DISABLED);
        // }
      },
      slidePrevTransitionEnd: function (swiper) {
        if (movies.length > 0) {
          swiper.allowSlideNext = true;
          swiper.navigation.nextEl[0].classList.remove(CLASS_IS_DISABLED);
        } else {
          swiper.navigation.nextEl[0].classList.add(CLASS_IS_DISABLED);
        }
      },
    },
  });

  swiper.controller.control = thumbs;
  thumbs.controller.control = swiper;

  slideRadios.forEach((radio, index) => {
    radio.addEventListener("change", function () {
      if (this.checked) {
        swiper.allowSlideNext = true;
        swiper.slides[swiper.activeIndex].classList.add(CLASS_IS_ACTIVE);
        swiper.navigation.nextEl[0].classList.remove(CLASS_IS_DISABLED);

        if (swiper.activeIndex === swiper.slides.length - 1 && buttonFinish) {
          buttonFinish.classList.remove(CLASS_IS_DISABLED);
          buttonFinish.disabled = false;
        }

        const existingObjectIndex = movies.findIndex(
          (item) => item.name === this.name
        );

        if (existingObjectIndex !== -1) {
          movies[existingObjectIndex].value = this.value;
        } else {
          movies.push({ name: this.name, value: this.value });
        }
      }
    });
  });

  if (buttonFinish) {
    buttonFinish.addEventListener("click", () => {
      const API_URL = "/api/";
      const paramName = "params";
      const combinedValue = movies.map((item) => item.value).join("");
      const params = new URLSearchParams();
      params.set(paramName, combinedValue);

      const url = new URL(window.location.href);
      url.search = params.toString();

      postData(`${API_URL}?act=s&${paramName}=${combinedValue}`)
        .then((response) => console.log(response.json()))
        .catch((error) => {
          console.error("Error:", error);
        });

      history.pushState(null, "", url.toString());
      Movies();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      document.body.classList.remove("is--1st-in");
      document.body.classList.remove("is--2nd-in");
      document.body.classList.remove("is--3rd-out");
      document.body.classList.add("is--2nd-out");
      document.body.classList.add("is--3rd-in");
    });
  }

  if (buttonsReset) {
    buttonsReset.forEach((buttonReset) => {
      buttonReset.addEventListener("click", () => {
        movies = [];
        swiper.slideTo(0);
        swiper.allowSlideNext = false;

        document.body.classList.remove("is--2nd-out");
        document.body.classList.remove("is--1st-in");
        document.body.classList.remove("is--3rd-in");
        document.body.classList.add("is--3rd-out");
        document.body.classList.add("is--2nd-in");

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });

        const url = new URL(window.location.href);
        url.search = "";

        history.pushState(null, "", url.toString());

        swiper.slides.forEach((slide) => {
          slide.classList.remove(CLASS_IS_ACTIVE);
        });

        slideRadios.forEach((radio, index) => {
          radio.checked = false;
        });
      });
    });
  }
};
