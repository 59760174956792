import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

Swiper.use([Autoplay]);

export const Banners = () => {
  const breakpoints = {
    400: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 7,
    },
    1024: {
      slidesPerView: 8,
    },
    1366: {
      slidesPerView: 11,
    },
    1440: {
      slidesPerView: 12,
    },
    1680: {
      slidesPerView: 13,
    },
    1920: {
      slidesPerView: 15,
    },
  };
  const swiperTop = new Swiper(".m-banners__line--top .swiper", {
    slidesPerView: 3,
    spaceBetween: 5,
    speed: 3000,
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
    },
    breakpoints: breakpoints,
  });
  const swiperBottom = new Swiper(".m-banners__line--bottom .swiper", {
    slidesPerView: 3,
    spaceBetween: 5,
    speed: 2500,
    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    breakpoints: breakpoints,
  });

  swiperTop.autoplay.stop();
  swiperBottom.autoplay.stop();
  setTimeout(() => {
    swiperTop.autoplay.start();
    swiperBottom.autoplay.start();
  }, 4000);
};
